/*Theme Colors*/
.course_details .card-footer {
  background-color: #f6f5ff;
  margin-bottom: 1rem;
}
.course_details .sharebtn-box {
  position: relative;
}
.course_details .sharebtn {
  background: rgba(0, 0, 0, 0.2);
  color: #ffffff;
  padding: 4px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  float: right;
  margin: 1rem 2.5rem;
  border-color: #fff;
}
.course_details .sharebtn img {
  vertical-align: revert;
  margin-right: 0.25rem;
  height: 10px;
}
.course_details .sharebtn-dropdown {
  background: #F5F5F5;
  border-radius: 4px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1607843137);
  color: #2C2C2C;
  position: absolute;
  width: 100%;
  right: 20%;
  top: 48px;
  z-index: 2;
}
.course_details .sharebtn-dropdown .share-item {
  display: flex;
  padding: 10px 15px;
  cursor: pointer;
}
.course_details .sharebtn-dropdown .share-item .icon {
  display: inline-flex;
  margin-right: 10px;
  position: relative;
  top: 1px;
}
.course_details .sharebtn-dropdown .share-item .item-name {
  display: inline-flex;
}
.course_details .sharebtn-dropdown .share-item:hover {
  background: #3273DE !important;
  color: #fff !important;
  text-shadow: none !important;
  transition: none;
  border-radius: 4px;
}
.course_details .sharebtn-dropdown .share-item:hover .icon img {
  filter: grayscale(100%) brightness(500%) sepia(0%) hue-rotate(-50deg) saturate(100%) contrast(2);
}
.course_details .percentageValue {
  color: #fff;
}
.course_details .right-bar {
  position: relative;
}
.course_details .right-bar::after {
  content: "";
  position: absolute;
  right: 0;
  top: 3px;
  width: 1.5px;
  height: 1.2rem;
  background-color: #dadada;
}
.course_details h4 {
  padding-left: 1.5rem;
  font-size: 1.5rem;
  color: #ffffff;
}
.course_details .custom-width {
  width: 50%;
}
.btn-as-p {
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  transition: 0.2s;
  margin: 0;
  padding: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
}
.error-body {
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.86px;
  text-align: left;
}
.error-container {
  background: #292929;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  display: flex;
  padding: 30px 36px;
  gap: 24px;
  border: 4px solid #737373;
  align-items: center;
}
.error-message {
  color: #e0e0e0;
  font-size: 16px;
  gap: 0px;
  line-height: 1.5;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1024px) {
  .course_details .custom-width {
    width: 62%;
  }
}
.course_details .custom-width .pi-star {
  color: #fff;
}
.course_details .enrollbtn {
  width: 100%;
}
.course_details .sub-menu-list .sub-menu img {
  vertical-align: unset;
  top: 2px;
  position: relative;
}
.course_details .rating-container {
  border: 1px solid #e8e8e8;
}
.course_details .rating-container .rating-title {
  font-size: 1.7rem;
  font-weight: bold;
}
.course_details .rating-container .feedback-content .feedback-input {
  border: 1px solid #e8e8e8;
  width: 100%;
  height: 8rem;
  resize: none;
}
.course_details .rating-container .ratingImg img {
  margin-right: 0.5rem;
}
.course_details .roleSkill {
  border: 1px solid #e8e8e8;
}
.course_details .roleSkill .roleSkillsTitle {
  font-size: 1.7rem;
  font-weight: bold;
}
.course_details .roleSkill .btn-view {
  font-size: 1.5rem;
  color: #2799f6;
  font-weight: normal;
  text-decoration: none;
}
.course_details .roleSkill .submitapproval {
  padding: 0.7rem;
  text-transform: none;
  min-width: 19rem;
}
.course_details .roleSkill .footSection {
  flex-wrap: wrap;
}
.course_details .scroller {
  max-height: 10rem;
  overflow: auto;
  scrollbar-width: thin;
}
.course_details .scroller::-webkit-scrollbar {
  width: 0.5rem;
  background-color: #d9d6d6;
}
.course_details .scroller::-webkit-scrollbar-thumb {
  background-color: #888888;
}
.course_details .horizontal-tab-block {
  border-top: 1px solid #e1e1e1;
  padding: 0 10px 10px 10px;
  position: relative;
}
.course_details .horizontal-tab-block.blue-border {
  border: 2px solid #bdd4e7;
}

.list-view .list-view-block {
  border: 1px solid #e8e8e8;
}
.list-view .list-view-block .list-view-block-img {
  border-radius: 5px;
  width: 98px;
  height: 68px;
}
@media (max-width: 991px) {
  .list-view .list-view-block .list-view-block-img {
    width: auto;
    height: auto;
  }
}
.list-view .list-view-block .text-padding {
  padding: 1rem;
}
.list-view .list-view-block .inner-module-name {
  background-color: #f5f5f5;
  margin-bottom: 0;
  font-weight: bold;
  visibility: hidden;
  height: 0;
}
.list-view .list-view-block .vc-div {
  background-color: #f5f5f5;
  padding: 1rem 0;
}
.list-view .list-view-block .vc-div .list-view-block-img {
  height: 2rem !important;
  width: 2.5rem !important;
  vertical-align: text-bottom;
}
@media (max-width: 991px) {
  .list-view .list-view-block .vc-div .list-view-block-img {
    width: 2rem;
    height: 2.5rem;
  }
}
.list-view .list-view-block .vc-div .locator {
  color: #6b6b6b;
  font-size: 2.1rem;
}
.list-view .list-view-block .vc-div .vc-link {
  text-decoration: none;
  color: #2799f6;
}
.list-view .head-text {
  font-size: 1.7rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
@media screen and (max-width: 767px) and (orientation: portrait) {
  .list-view .head-text {
    text-align: center;
  }
}
.list-view .sub-text {
  font-size: 1.4rem;
}
@media screen and (max-width: 767px) and (orientation: portrait) {
  .list-view .sub-text {
    text-align: justify;
  }
}
.list-view .module {
  color: #2799f6;
}
.list-view .list-footer {
  background-color: #ececed;
  display: flex;
  padding: 3px 15px;
  color: #707070;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: 991px) {
  .list-view .list-footer .inner-span {
    display: block;
  }
}
.list-view .list-footer img {
  vertical-align: unset;
}
.list-view .list-footer .progress {
  width: 30%;
  background-color: #d9d9d9;
  margin-top: 1px;
  height: 5px !important;
}
.list-view .list-footer .primary-btn {
  margin-left: auto;
}

.learning-accordion {
  background-color: #ececed;
  border-top: 1px solid #e1e1e1;
}
.learning-accordion .accordion-content {
  border: 1px solid #e1e1e1;
  background-color: #fff;
}
.learning-accordion .accordion-content .head-text {
  padding-top: 0.5rem;
}
@media (max-width: 1200px) {
  .learning-accordion .accordion-content .head-text {
    padding-top: 1rem;
  }
}
.learning-accordion .list-footer {
  color: #000;
  background-color: transparent;
}
.learning-accordion .inner-content .head-text {
  margin-bottom: 0;
}
.learning-accordion .inner-content .text-padding {
  padding: 1rem 0;
}
.learning-accordion .inner-content .text-padding .last-visited {
  color: #2799f6;
}
.learning-accordion .accordion-collapse .list-view {
  background-color: #fff;
}
.learning-accordion .accordionBtn {
  background-image: url("../../assets/images/downarrow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
  background-color: transparent;
  border: none;
  width: 100%;
}
.learning-accordion .accordionBtn:focus {
  background-color: transparent;
}
.learning-accordion .accordionBtn:active {
  background-color: transparent;
}
.learning-accordion .accordionBtn:hover {
  background-color: transparent;
}
.learning-accordion .accordionBtn:focus-visible {
  box-shadow: none;
}
.learning-accordion .inner-module-name {
  background-color: #f5f5f5;
  margin-bottom: 0;
  padding: 1rem 0;
  font-weight: bold;
}
.learning-accordion .vc-div {
  background-color: #f5f5f5;
}
.learning-accordion .vc-div .list-view-block-img {
  height: 2rem;
  width: 2.5rem;
}
.learning-accordion .vc-div .locator {
  color: #6b6b6b;
  font-size: 2.1rem;
}
.learning-accordion .vc-div .vc-link {
  text-decoration: none;
  color: #2799f6;
}
.learning-accordion .alm-file-upload-conatainer-content {
  display: block;
  margin: 30px auto;
  position: relative;
}
.learning-accordion .alm-file-upload-content {
  width: 300px;
  margin: 0 auto;
}
.learning-accordion .alm-file-upload-content .alm-file-upload-icon {
  display: inline-block;
  vertical-align: middle;
  width: 3.1rem;
  height: 3.1rem;
  margin-right: 1rem;
}
.learning-accordion .alm-file-upload-content .alm-file-upload-input {
  display: inline-block;
  vertical-align: middle;
  width: 22%;
}
.learning-accordion .alm-file-upload-content .alm-file-upload-label {
  color: #707070;
  font-size: 1.5rem;
  vertical-align: middle;
  width: 30%;
  padding-left: 20px;
}
.learning-accordion .alm-file-upload-uploaded-files {
  position: absolute;
  left: 27%;
  bottom: -50px;
}
.learning-accordion .alm-file-upload-uploaded-files .navBackgroundColor {
  margin: 0 5px;
  display: block;
}

.prerequisite {
  padding: 1rem;
  border: 2px solid #bdd4e7;
  margin: 1rem 0;
}
.prerequisite h3 {
  font-weight: bold;
}
.prerequisite .list-view {
  background-color: #fff;
}

.accord-cls {
  background-color: #fafafa;
}

.ratingStyles {
  line-height: 18px;
  font-size: 1.7rem;
  display: inline-block;
  height: 20px;
  position: relative;
  top: 3px;
}

.ratingStar {
  font-weight: bold;
  font-size: 1.9rem;
  line-height: 18px;
  position: relative;
  top: 3px;
  margin: 0 3px 0 10px;
}

.blankstar {
  position: absolute;
  top: 0;
  z-index: 1;
}
.blankstar button {
  cursor: default !important;
}
.blankstar .pi-star {
  font-size: 2.1rem;
}

.fill-star {
  position: relative;
  z-index: 2;
}
.fill-star button {
  cursor: default !important;
}
.fill-star button:active {
  border: 1px solid transparent !important;
}

.ratingImg button:active {
  border: 1px solid transparent !important;
}

.file-link {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 150px;
  margin-bottom: -6px; /* Adjust this value as needed to limit the visible characters */
}

.learning-accordion .accordion-collapse .nested-inner-box .list-view {
  margin: 10px;
  margin-bottom: 0;
}
.learning-accordion .accordion-collapse .nested-inner-box .list-view .list-view-block {
  margin: 0 !important;
}
.learning-accordion .accordion-collapse .nested-inner-box .list-view:last-child {
  padding-bottom: 10px;
}

.Certificate-block {
  padding: 15px;
  border: 2px solid #bdd4e7;
}
.Certificate-block .list-view-block {
  background-color: #fff;
}

.horizontal-tab-block .nav.nav-tabs .nav-item {
  margin-right: 3.5rem !important;
}

.details.instructorName {
  position: relative;
  padding-left: 50px !important;
}
.details.instructorName span {
  position: absolute;
  left: 5px;
  top: -3px;
}/*# sourceMappingURL=CourseDetails.css.map */